import { round } from './helpers'

const properties = {
  filesize: {
    units: ['Bytes', 'KB', 'MB', 'GB', 'TB'],
    multiplier: 1024,
    visualMultiplier: 1000,

    // Round to 2 decimals for values < 10, 1 decimal for values < 100 and to 0 decimals otherwise
    // Example: 1.57 -> 1.57 vs. 10.57 -> 10.6 vs. 100.57 -> 101
    precision: (value) => (value < 10 ? 2 : value < 100 ? 1 : 0),
  },
  bandwidth: {
    units: ['bit', 'Kbit', 'Mbit', 'Gbit', 'TBit'],
    multiplier: 1000,

    // Round to 2 decimals for values < 10, 1 decimal for values < 100 and to 0 decimals otherwise
    // Example: 1.57 -> 1.57 vs. 10.57 -> 10.6 vs. 100.57 -> 101
    precision: (value) => (value < 10 ? 2 : value < 100 ? 1 : 0),
  },
}

/**
 * Convert a number of bytes to a human-readable string
 *
 * @param raw The raw number of bytes
 * @param type Whether the format refers to 'filesize' or 'bandwidth'
 * @param options
 */
export default function formatSize(raw, type, { inputUnit } = {}) {
  if (typeof raw !== 'number' || !Number.isFinite(raw) || raw < 0) {
    throw new Error(
      `Unexpected argument: Finite, non-negative number expected, received ${JSON.stringify(
        raw
      )}`
    )
  }

  let {
    units,
    multiplier,
    visualMultiplier = multiplier,
    precision,
  } = properties[type] ?? properties.filesize

  let startIndex
  if (inputUnit && units.indexOf(inputUnit) !== -1) {
    startIndex = units.indexOf(inputUnit)
  } else {
    startIndex = 0
  }

  // Reduce initially because we may skip units
  let reduced = raw

  // Iterate over all units to find the most fitting one
  for (let i = startIndex; i < units.length; i++) {
    let isLargestUnit = i === units.length - 1

    // The number is considered human-readable if no larger unit
    // can be assigned to it (without the value dropping below 1)
    if (reduced < visualMultiplier || isLargestUnit) {
      let roundedValue = round(reduced, precision(reduced))

      // If the reduced value is lower than 1024 but the rounded value is 1024,
      // use the next larger unit if available
      if (roundedValue === visualMultiplier && !isLargestUnit) {
        roundedValue = 1
        i++
      }
      return `${roundedValue.toLocaleString('de-CH')} ${units[i]}`
    }
    reduced /= multiplier
  }
}
