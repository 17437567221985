import { ConnectedModel } from '@orm'
import AboMain from './mobile/AboMain'
import AboXtraCard from './mobile/AboXtraCard'
import AboAdditionalPackage from './mobile/AboAdditionalPackage'

export default class MobileAbo extends ConnectedModel {
  static cache = 'memory'

  static entity = 'mobileAbo'

  static async pull() {
    return await this.api.get`/mobile2`
  }

  static fields() {
    return {
      main: this.attr(
        () => ({}),
        (value) => (value instanceof AboMain ? value : new AboMain(value))
      ),
      additionalPackages: this.attr(
        () => [],
        (value) => {
          if (value.length > 0 && value[0] instanceof AboAdditionalPackage)
            return value

          return value
            .filter((record) => record.available !== null)
            .map((record) => new AboAdditionalPackage(record))
        }
      ),
      totalPriceWithXtraCards: this.number(0),
      xtraCards: this.attr(
        () => [],
        (value) => {
          if (value.length > 0 && value[0] instanceof AboXtraCard) return value

          return value.map((record) => new AboXtraCard(record))
        }
      ),
      young: this.boolean(false),
    }
  }
}
