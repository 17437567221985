import { ConnectedSingletonModel } from '@orm'
import AboUsed from './mobile/AboUsed'

// Summary data for usage in the current and previous
export default class MobileUsage extends ConnectedSingletonModel {
  static allowEmptyInstance = false
  static entity = 'mobileUsage'

  // Set cache to only pull every 15 minutes
  static cache = ['sessionStorage', { expires: 15 * 60 }]

  static pull({ number }) {
    return this.api.get`/mobile2/${number}/used`
  }

  static fields() {
    return {
      actual: this.attr(
        () => [],
        (value) => {
          if (value.length > 0 && value[0] instanceof AboUsed) return value

          return value.map((record) => new AboUsed(record))
        }
      ),
      last: this.attr(
        () => [],
        (value) => {
          if (value.length > 0 && value[0] instanceof AboUsed) return value

          return value.map((record) => new AboUsed(record))
        }
      ),
    }
  }

  get currentMonth() {
    return this.actual
  }

  get previousMonth() {
    return this.last
  }
}
