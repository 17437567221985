import { Model } from '@orm'

export default class AboUsed extends Model {
  static private = true
  static entity = 'mobile/used'

  static fields() {
    // These fields are a 1:1 mapping from the source service submitting the data
    // to sasag, therefore all weird inconsistencies are kept as-is
    return {
      // What kind of usage? (DATA, DATAR, VOICE, VOICER, SMS, SMSR)
      Service: this.string(),

      // Unknown purpose
      BytesIn: this.string(),

      // Total price for the usage
      Price: this.string(),

      // Part of the price that is inclusive to the subscription
      InclusivePrice: this.string(),

      // Part of the price that is not inclusive to the subscription (paid extra)
      IncommingPrice: this.string(),

      // *Total* number of minutes in this usage (if VOICE/VOICER),
      // "inclusive" naming is inaccurate
      InclusiveMinutes: this.string(),

      // Number of bytes in this usage (if DATA/DATAR)
      Volume: this.string(),

      // *Total* number of messages in this usage (if SMS/SMSR),
      // "inclusive" naming is inaccurate
      InclusiveConnection: this.string(),
    }
  }

  get isData() {
    return this.Service === 'DATA' || this.Service === 'DATAR'
  }

  get isCalls() {
    return this.Service === 'VOICE' || this.Service === 'VOICER'
  }

  get isMessages() {
    return this.Service === 'SMS' || this.Service === 'SMSR'
  }

  get isRoaming() {
    return (
      this.Service === 'DATAR' ||
      this.Service === 'VOICER' ||
      this.Service === 'SMSR'
    )
  }

  get cost() {
    return Number(this.IncommingPrice)
  }

  get usedUnits() {
    if (this.isData) {
      return Number(this.Volume)
    } else if (this.isCalls) {
      return Number(this.InclusiveMinutes)
    } else if (this.isMessages) {
      return Number(this.InclusiveConnection)
    }
  }
}
