import { Model } from '@orm'
import * as date from 'date-fns'

export default class AboAdditionalPackage extends Model {
  static private = true
  static entity = 'mobile/additionalPackage'

  static fields() {
    return {
      soldDate: this.string(),
      amount: this.number(),
      text: this.string(),
      price: this.number(),
      billed: this.boolean(),
      vat: this.number(),
      serviceId: this.number(),
      extProductid: this.number(),
      untilDate: this.string(),
      exhaustedDate: this.string(),
      available: this.number(),
      typeIfAvailable: this.string(),
    }
  }

  get _textParts() {
    return this.text.split(/\/(?= ?gültig bis) /iu).map((part) => part.trim())
  }

  get title() {
    return this._textParts[0]
  }

  get validityText() {
    return this._textParts[1] ?? null
  }

  get soldDateObject() {
    return date.startOfDay(date.parse(this.soldDate, 'y-MM-dd', new Date()))
  }

  get untilDateObject() {
    return date.startOfDay(date.parse(this.untilDate, 'y-MM-dd', new Date()))
  }

  get exhaustedDateObject() {
    return date.startOfDay(date.parse(this.exhausteDate, 'y-MM-dd', new Date()))
  }

  get isData() {
    return this.typeIfAvailable === 'data'
  }

  get isVoice() {
    return this.typeIfAvailable === 'voice'
  }
}
