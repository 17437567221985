import { Model } from '@orm'
import * as date from 'date-fns'

export default class AboXtraCard extends Model {
  static private = true
  static entity = 'mobile/xtraCard'

  static fields() {
    return {
      start: this.string(),
      name: this.string(),
      price: this.number(),
      articleNumber: this.number(),
      addTxt: this.string(''),
    }
  }

  get startDate() {
    return date.startOfDay(date.parse(this.aboStart, 'y-MM-dd', new Date()))
  }

  get startMonth() {
    return date.startOfMonth(this.startDate)
  }
}
