import { ConnectedSingletonModel } from '@orm'

// A slice of summary data for a particular month
// Solved as a subclass as it needs to maintain an independent payload
export default class LegacyMobileConnectionIncludedUnits extends ConnectedSingletonModel {
  static allowEmptyInstance = false
  static entity = 'mobileConnectionIncludedUnits'

  // Set cache to only pull every 15 minutes
  static cache = ['sessionStorage', { expires: 15 * 60 }]

  static pull({ from, to, number }) {
    return this.api.get`/mobile/${number}/used/${from}/${to}`
  }

  static fields() {
    return {
      phoneNational: this.number(),
      smsNational: this.number(),
      dataNationalMB: this.number(),
      phoneToEU: this.number(),
      phoneInEU: this.number(),
      smsEU: this.number(),
      dataEUMB: this.number(),
    }
  }
}
