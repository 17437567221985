import { parseProductName } from '@/assets/util/helpers'
import { Model } from '@orm'
import * as date from 'date-fns'

export default class AboMain extends Model {
  static private = true
  static entity = 'mobile/abo'

  static fields() {
    return {
      name: this.string(),
      isCurrent: this.boolean(),
      price: this.number(),
      aboStart: this.string(''),
      number: this.uid(),
      additionalNumbers: this.attr(),
      subscriptionId: this.number(),
      extRef: this.number(),
      included: this.attr(),
      details: this.attr(),
    }
  }

  _handleInfinity(value) {
    return value === -1 ? Infinity : value
  }

  _handleDataMB(value) {
    return this._handleInfinity(value) * 1024 * 1024
  }

  get includedUnits() {
    return {
      national: {
        calls: this._handleInfinity(this.included.phoneNational),
        messages: this._handleInfinity(this.included.smsNational),
        data: this._handleDataMB(this.included.dataNationalMB),
      },
      zone3: {
        calls: this._handleInfinity(this.included.phoneForeign),
        messages: this._handleInfinity(this.included.smsForeign),
        data: this._handleDataMB(this.included.dataForeignMB),
      },
      zone4: {
        calls:
          this.included.additionalCountries !== false
            ? this._handleInfinity(this.included.additionalCountries.phoneZone4)
            : 0,
        messages:
          this.included.additionalCountries !== false
            ? this._handleInfinity(this.included.additionalCountries.smsZone4)
            : 0,
        data:
          this.included.additionalCountries !== false
            ? this._handleDataMB(this.included.additionalCountries.dataZone4MB)
            : 0,
      },
    }
  }

  get hasIncludedNationalUnits() {
    return (
      this.includedUnits.national.calls +
        this.includedUnits.national.data +
        this.includedUnits.national.messages >
      0
    )
  }

  get hasIncludedZone3Units() {
    return (
      this.includedUnits.zone3.calls +
        this.includedUnits.zone3.data +
        this.includedUnits.zone3.messages >
      0
    )
  }

  get hasIncludedZone4Units() {
    return (
      this.includedUnits.zone4.calls +
        this.includedUnits.zone4.data +
        this.includedUnits.zone4.messages >
      0
    )
  }

  get zone4Usage() {
    if (!this.included.additionalCountries) return null

    return {
      calls: this._handleInfinity(
        this.included.additionalCountries.phoneZone4usedCurrentMonth
      ),
      messages: this._handleInfinity(
        this.included.additionalCountries.smsZone4usedCurrentMonth
      ),
      data: this._handleDataMB(
        this.included.additionalCountries.dataZone4MBusedCurrentMonth
      ),
    }
  }

  get nameParts() {
    return parseProductName(this.name)
  }

  get startDate() {
    return date.startOfDay(date.parse(this.aboStart, 'y-MM-dd', new Date()))
  }

  get startMonth() {
    return date.startOfMonth(this.startDate)
  }
}
