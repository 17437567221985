import { ConnectedSingletonModel } from '@orm'

export default class MobileStaticData extends ConnectedSingletonModel {
  static entity = 'mobile_static_data'

  // Set cache to only pull every 15 minutes
  // Since this is non-sensitive data, it can be put into localStorage
  static cache = ['localStorage', { expires: 15 * 60 }]

  static async pull() {
    let { mobile } = await this.api.get`/static`
    return mobile
  }

  static fields() {
    return {
      info: this.attr({}),
      packages: this.attr({}),
    }
  }
}
