import Vue from 'vue'
import { Model } from '@vuex-orm/core'
import * as cachingImplementations from './caching-implementations'
import { MISS, CAUTIOUS_HIT, DEFINITIVE_HIT } from './caching'

// Symbols are used as hidden class property names
const REACTIVE_DATA_INSTANCE = Symbol('Observable data for a connected model')
const REACTIVE_DATA = Symbol('Instantiate and return reactive data')
const CACHE_INSTANCE = Symbol('Instance of the caching implementation')
const CACHE = Symbol('Instantiate and return the caching implementation')
const API = Symbol()

// For debugging
const CONSOLE_STYLE = [
  'margin-top: 0.3rem',
  'margin-bottom: 0.3rem',
  'padding: 3px 8px',
  'color: white',
  'border-radius: 3px',
  'font-weight: bold',
  'display: block',
].join(';')
const CONSOLE_OFF_STYLE = 'line-height: 1.6'

/**
 * A connected model is a model that fetches and
 * (optionally) persists model data from/to a source.
 */
export default class ConnectedModel extends Model {
  static defaultMeta = undefined

  // Internal reactive data, exposed through static getters below
  static get [REACTIVE_DATA]() {
    if (!this[REACTIVE_DATA_INSTANCE]) {
      this[REACTIVE_DATA_INSTANCE] = Vue.observable({
        hasData: false,
        hasAnyData: false,
        hasFreshData: false,
        isPulling: false,
        isPushing: false,
        payload: undefined,
        meta: { ...this.defaultMeta },
      })
    }

    return this[REACTIVE_DATA_INSTANCE]
  }

  // This is implemented as a once-evaluated getter to ensure that every model
  // class extending this gets its own API (especially its own cancellation token).
  static get api() {
    if (!this[API]) {
      this[API] = $nuxt.$services.api.fork()
    }

    return this[API]
  }

  // Cache strategies can be selected by name from the caching-implementations.js
  // Alternatively, a custom function can be used
  static cache = 'sessionStorage'

  // Model data has been loaded (freshly pulled or from cache)
  static get hasData() {
    return this[REACTIVE_DATA].hasData
  }

  // Any model data has been loaded (not necessary matching current payload)
  static get hasAnyData() {
    return this[REACTIVE_DATA].hasAnyData
  }

  // Model has freshly pulled data
  static get hasFreshData() {
    return this[REACTIVE_DATA].hasFreshData
  }

  // Model data is currently being fetched
  static get isPulling() {
    return this[REACTIVE_DATA].isPulling
  }

  // Model data is currently being written
  static get isPushing() {
    return this[REACTIVE_DATA].isPushing
  }

  // The pull payload of the current data
  static get payload() {
    return this[REACTIVE_DATA].payload
  }

  // Metadata of the current model
  static get meta() {
    return this[REACTIVE_DATA].meta
  }

  // Pull data for model
  static pull() {
    throw new Error(
      'Static method "pull" must be implemented in connected models.'
    )
  }

  // Instantiate and return the caching implementation
  static get [CACHE]() {
    if (typeof this[CACHE_INSTANCE] === 'undefined') {
      if (typeof this.cache === 'string') {
        // Caching implementation as string (i.e. pick one of the pre-defined)
        this[CACHE_INSTANCE] = cachingImplementations[this.cache](this)
      } else if (typeof this.cache === 'function') {
        // Caching implementation as callable function
        this[CACHE_INSTANCE] = this.cache(this)
      } else if (Array.isArray(this.cache)) {
        // Caching implementation as tuple of implementation (string or function) and options
        let [implementation, options] = this.cache

        if (typeof implementation === 'string') {
          implementation = cachingImplementations[implementation]
        }

        this[CACHE_INSTANCE] = implementation(this, options)
      } else {
        // Caching implementation as already instantiated cache or false
        this[CACHE_INSTANCE] = this.cache
      }
    }

    return this[CACHE_INSTANCE]
  }

  static async clear() {}

  // Clear the records list
  static async clearRecords() {
    await super.deleteAll()
    this[REACTIVE_DATA].hasData = false
    this[REACTIVE_DATA].hasAnyData = false
    this[REACTIVE_DATA].hasFreshData = false
  }

  // Clear the model cache
  static async clearCache() {
    await this[CACHE].clear()

    // If no fresh data is available, the model needs to be cleared
    if (this.hasData && !this.hasFreshData) {
      await this.clearRecords()
    }
  }

  // Load model data from cache or source
  // An optional payload can be passed and will be forwarded to the pull() method
  // This can be useful for fetching only a slice of the data (e.g. for pagination)
  static async load({ refresh = false, ...payload } = {}) {
    console.debug(
      '%cLOAD%c\n📔 Model: %o\n🎛️ Payload: %o\n✨ Force refresh: %o\n🔎 Has pre-existing data: %o\n📥 Is already pulling: %o',
      `${CONSOLE_STYLE}; background-color: #E4AD5A`,
      CONSOLE_OFF_STYLE,
      this.entity,
      payload,
      refresh,
      this.hasData,
      this.isPulling
    )

    if (Object.keys(payload).length === 0) {
      payload = undefined
    }

    // If a payload has been passed (or refresh is enforced), cancel possible
    //  running requests. This ensures that only the latest load() call will
    // put data into the store.
    //
    // Since load() calls with no payload are not expected to return different
    // results in a matter of seconds, running requests with no payload are not
    // cancelled and instead the second load() call will be canceled.
    if (payload || refresh) {
      let cancelled = this.api.cancel()

      if (cancelled) {
        console.debug(
          '%cABORT%c\n📔 Model: %o\n\n',
          `${CONSOLE_STYLE}; background-color: #FFE663; color: black`,
          CONSOLE_OFF_STYLE,
          this.entity,
          'Aborted running pull, fetching fresh data instead'
        )

        // Briefly transfer control to the event loop if a request was canceled
        // This allows for the running request to cancel and set the 'isPulling'
        // state of the model back to 'false'
        await new Promise((resolve) => setTimeout(resolve, 0))
      }
    }

    if (
      (this.hasData && typeof payload === 'undefined' && !refresh) ||
      this.isPulling ||
      this.isPushing
    ) {
      let reason
      switch (true) {
        case this.isPulling:
          reason = 'model is already pulling data'
          break
        case this.isPushing:
          reason = 'model is currently pushing data'
          break
        case this.hasData:
          reason =
            'model has already been loaded. Use the "refresh" option to force.'
          break
      }
      console.debug(
        '%cABORT%c\n📔 Model: %o\n\n%s',
        `${CONSOLE_STYLE}; background-color: #FFE663; color: black`,
        CONSOLE_OFF_STYLE,
        this.entity,
        `Aborted loading because ${reason}`
      )
      return
    }

    let modelData = this[REACTIVE_DATA]

    modelData.isPulling = true

    let debugHit = await this[CACHE].check(payload)
    console.debug(
      '%cCACHE%c\n📔 Model: %o\n💾 Strategy: %o\n🔎 Hit: %c%s',
      `${CONSOLE_STYLE}; background-color: #7E5EB4`,
      CONSOLE_OFF_STYLE,
      this.entity,
      this.cache,
      debugHit === MISS
        ? 'color: #E84E00'
        : debugHit === CAUTIOUS_HIT
        ? 'color: #AB7100'
        : 'color: #76B82A',
      debugHit === MISS
        ? 'no'
        : debugHit === CAUTIOUS_HIT
        ? 'yes (but might need update)'
        : 'yes'
    )

    // Load data from cache if appropriate
    if (this.cache) {
      let cacheHit = await this[CACHE].check(payload)

      if (cacheHit === MISS) {
        modelData.hasData = false
      } else {
        let result = await this[CACHE].read(payload)
        if (result.meta) {
          Object.assign(this.meta, result.meta)
        }

        // Commit cached data to store
        super.create({
          data: result.data,
        })

        modelData.hasFreshData = cacheHit === DEFINITIVE_HIT
        modelData.hasData = true
        modelData.hasAnyData = true

        // Don't fetch fresh data after a definitive hit
        if (cacheHit === DEFINITIVE_HIT && !refresh) {
          modelData.isPulling = false
          modelData.payload = payload
          return
        }
      }
    } else {
      modelData.hasData = false
    }

    console.debug(
      '%cPULL%c\n📔 Model: %o',
      `${CONSOLE_STYLE}; background-color: #05BED8`,
      CONSOLE_OFF_STYLE,
      this.entity
    )

    // Fetch data from source
    let items
    try {
      items = await this.pull(payload)
    } catch (error) {
      if ($nuxt.$axios?.isCancel(error) || error?.name === 'AbortError') {
        console.debug(
          '%cPULL CANCELED%c\n📔 Model: %o',
          `${CONSOLE_STYLE}; background-color: #CE540F`,
          CONSOLE_OFF_STYLE,
          this.entity
        )
      } else {
        console.debug(
          '%cPULL FAILED%c\n📔 Model: %o',
          `${CONSOLE_STYLE}; background-color: #CE540F`,
          CONSOLE_OFF_STYLE,
          this.entity,
          error
        )
      }

      modelData.isPulling = false
      return
    }

    console.debug(
      '%cRESULT%c\n📔 Model: %o\n📊 Data: %o\n📐 Metadata: %o',
      `${CONSOLE_STYLE}; background-color: #7BAE26`,
      CONSOLE_OFF_STYLE,
      this.entity,
      items,
      JSON.parse(JSON.stringify(this.meta))
    )

    if (this.cache) {
      await this[CACHE].write(
        items,
        payload,
        JSON.parse(JSON.stringify(this.meta))
      )
    }

    // Commit live data to store
    super.create({ data: items })

    modelData.isPulling = false
    modelData.hasFreshData = true
    modelData.hasData = true
    modelData.hasAnyData = true
    modelData.payload = payload
  }

  async $update(data, options = {}) {
    // Defer to static 'update' method to avoid duplicating logic
    return await ConnectedModel.update.call(this.$self(), {
      ...options,
      where: this.$self().entity,
      data,
    })
  }

  static async insert(options) {
    let { data, push = true, refresh = true } = options

    // Disallow inserting before any data is available
    if (!this.hasData) {
      throw new Error(
        'Cannot insert because the model has not yet loaded any data'
      )
    }

    // Disallow inserting while a previous update is still in progress
    if (this.isPushing || this.isPulling) {
      throw new Error(
        'Cannot insert because the model is currently pulling or pushing data'
      )
    }

    // Handle data pushing with an optimistic store
    if (push && typeof this.pushInsert === 'function') {
      // Prevent insetion of multiple records
      if (Array.isArray(data)) {
        throw new Error(
          'Cannot insert multiple records at once to a connected model with a push endpoint'
        )
      }

      let insertionResult = await super.insert({ data })
      let newRecord = insertionResult?.[this.entity]?.[0]
      let newData = { ...newRecord }

      try {
        this[REACTIVE_DATA].isPushing = true
        await this.pushInsert(newData, { insertOptions: options })
        this[REACTIVE_DATA].isPushing = false

        // After inserting, pull fresh data from the API
        if (refresh) {
          await this.clearCache()

          // We don't wait for loading to finish, this would delay
          // the insertion process and the results are reactive anyway
          this.load({ refresh: true })
        }
      } catch (error) {
        // Delete inserted record if pushing failed
        await super.delete(newRecord.$id)
        this[REACTIVE_DATA].isPushing = false

        if (error) {
          throw error
        } else {
          throw new Error('Pushing model data failed for unknown reasons')
        }
      }
    } else {
      await super.insert({ data })
    }
  }

  static async update(options) {
    let { where, data, push = true, refresh = true } = options

    // Disallow updating before any data is available
    if (!this.hasData) {
      throw new Error(
        'Cannot update because the model has not yet loaded any data'
      )
    }

    // Disallow updating while a previous update is still in progress
    if (this.isPushing || this.isPulling) {
      throw new Error(
        'Cannot update because the model is currently pulling or pushing data'
      )
    }

    // Handle data pushing with an optimistic store
    if (push && typeof this.pushUpdate === 'function') {
      // Get old data from instance
      let oldData = JSON.parse(JSON.stringify(super.find(where)))
      delete oldData.$id

      await super.update({ where, data })

      // Get new data from instance
      let newData = JSON.parse(JSON.stringify(super.find(where)))
      delete newData.$id

      try {
        this[REACTIVE_DATA].isPushing = true
        await this.pushUpdate(newData, oldData, {
          updatedModel: super.find(where),
          updateOptions: options,
        })
        this[REACTIVE_DATA].isPushing = false

        // After updating, pull fresh data from the API
        if (refresh) {
          await this.clearCache()

          // We don't wait for loading to finish, this would delay
          // the update process and the results are reactive anyway
          this.load({ refresh: true })
        }
      } catch (error) {
        // Restore old record data if pushing failed
        await super.update({ where, data: oldData })
        this[REACTIVE_DATA].isPushing = false

        if (error) {
          throw error
        } else {
          throw new Error('Pushing model data failed for unknown reasons')
        }
      }
    } else {
      await super.update({ where, data })
    }
  }

  static async delete(options) {
    if (typeof options !== 'object') {
      options = { where: options }
    }

    let { where, push = true, refresh = true } = options

    // Disallow deleting before any data is available
    if (!this.hasData) {
      throw new Error(
        'Cannot delete because the model has not yet loaded any data'
      )
    }

    // Disallow deleting while a previous update is still in progress
    if (this.isPushing || this.isPulling) {
      throw new Error(
        'Cannot delete because the model is currently pulling or pushing data'
      )
    }

    // Handle data pushing with an optimistic store
    if (push && typeof this.pushDelete === 'function') {
      let record = super.find(where)
      let data = { ...record }

      if (!record) {
        throw new Error('Cannot delete because no single record could be found')
      }

      await super.delete(where)

      try {
        this[REACTIVE_DATA].isPushing = true
        await this.pushDelete(data, { deleteOptions: options })
        this[REACTIVE_DATA].isPushing = false

        // After deleting, pull fresh data from the API
        if (refresh) {
          await this.clearCache()

          // We don't wait for loading to finish, this would delay
          // the update process and the results are reactive anyway
          this.load({ refresh: true })
        }
      } catch (error) {
        // Re-insert deleted record if pushing failed
        await super.insert(record)
        this[REACTIVE_DATA].isPushing = false

        if (error) {
          throw error
        } else {
          throw new Error('Pushing model data failed for unknown reasons')
        }
      }
    } else {
      return await super.delete(where)
    }
  }
}
